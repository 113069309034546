import React from "react"
import Layout from "src/component/layout/layout"
import { Seo } from "src/component/common/seo"

export default function HumanRightsPolicy() {
    return (
        <>
            <Layout>
                <div className="p-privacy">
                    <div className="p-privacy__inner c-inner-primary">
                        <div className="l-oldContent">
                            <div className="c-title01">
                                <h1 className="p-contact__title__text c-title01__text">人権ポリシー</h1>
                            </div>
                            <div className="l-oldContent__inner">
                                <section className="c-section">
                                    <div className="l-frame">
                                        <div className="t-privacy-wrap">
                                            <p>DATAFLUCTは、データビジネスを展開していく中で、自らの企業活動がステークホルダーの人権に及ぼす負の影響を低減し、その発生を防止する必要があると考えています。また、データを余すことなく活用し、企業と社会に新しい価値をもたらすことで、データの力で持続可能な豊かな世界の実現ができると考えています。</p>
                                        </div>
                                        <div className="t-privacy-wrap" style={{ paddingBottom: "1.5rem" }}>
                                            <h1 style={{ fontWeight: "bold", fontSize: "1.5rem" }}>◆ DATAFLUCTグループ人権方針</h1>
                                        </div>

                                        <div className="t-privacy-wrap">
                                            <h2>人権尊重への考え方</h2>
                                            <div>
                                                <p>
                                                    &nbsp;&nbsp;DATAFLUCTおよびその連結子会社であるDATAFLUCT international Pte.Ltd.は、さまざまなステークホルダーのみなさまと対話・共創しながら、データの力で経済や産業だけではなく、企業関係や社会環境、地球環境が持続可能な社会の実現をめざすことを、その存在意義「Purpose」としています。
                                                </p>
                                                <p>
                                                    &nbsp;&nbsp;このPurpose実現に向け、人権を尊重した企業活動の推進は不可欠な取り組みです。
                                                </p>
                                                <p>
                                                    &nbsp;&nbsp;DATAFLUCTグループは、あらゆる企業活動の場面において、基本的人権を尊重し、人種、信条、国籍、民族、宗教など、いかなる理由であっても差別行為を許しません。また、賃金の未払いや過剰・不当な労働、パワーハラスメントやセクシュアルハラスメントなどの個人の尊厳を損なう行為を許しません。
                                                </p>
                                                <p>
                                                    &nbsp;&nbsp;また、DATAFLUCTグループはデータを扱うことにおいて、データ保護とプライバシーやAIなどを使ったサービスの適正利用に努めます。また人権課題に配慮したサービスの提供を通じて、社会への負の影響を防止や軽減に努め、DATAFLUCTグループが提供するサービス価値を向上していきます。
                                                </p><br />
                                            </div>
                                        </div>

                                        <div className="t-privacy-wrap">
                                            <h2>国際基準の支持</h2>
                                            <p>&nbsp;&nbsp;DATAFLUCTグループは、上記の考えのもと、以下の文書により定められたものを含め、事業や技術に関連する国際的に認められた人権の基準を支持します。該当地域の国内法令が国際的に認められた人権と両立できない場合には、国際的な人権の原則を尊重する方法を追求します。</p>
                                            <ul style={{ listStyleType: "disc", marginLeft: "40px" }}>
                                                <li>
                                                    国際人権章典（「世界人権宣言」およびこれを条約化した「市民的及び政治的権利に関 する国際規約ならびに経済的、社会的および文化的権利に関する国際規約」）
                                                </li>
                                                <li>
                                                    労働における基本的原則と権利に関する国際労働機関(ILO) 宣言に定められた、ILO 中 核的労働基準 5 分野 10 条約（結社の自由及び団体交渉権の効果的な承認、あらゆる形態の強制労働の禁止、児童労働の実効的な廃止、雇用及び職業における差別の排除、安全で健康的な労働環境）
                                                </li>
                                                <li>
                                                    国際連合 ビジネスと人権指導原則（UNGP）
                                                </li>
                                                <li>
                                                    OECD 多国籍企業行動指針
                                                </li>

                                            </ul>
                                        </div>

                                        <div className="t-privacy-wrap">
                                            <h2>人権方針の目的と対象範囲</h2>
                                            <p>
                                                &nbsp;&nbsp;DATAFLUCTグループの全役員・全従業員（有期契約社員・嘱託・パートタイマーを含む）に適用します。またDATAFLUCTグループは、調達取引先、ビジネスパートナー、お客さまにも、本方針のご理解とともに、人権の尊重に努めて頂くよう、働きかけていきます。
                                            </p>
                                        </div>
                                        <div className="t-privacy-wrap">
                                            <h2>人権デュー・ディリジェンスの推進</h2>
                                            <p>
                                                &nbsp;&nbsp;DATAFLUCTは人権デュー・ディリジェンスの実効性をより高めるべく、2024年度より人権影響評価を実施します。DATAFLUCTグループは、事業活動において起こりうる顕在化したまたは潜在的な人権に対する負の影響を継続的に検証し、未然防止または軽減に努めます。
                                            </p>
                                        </div>
                                        <div className="t-privacy-wrap">
                                            <h2>DATAFLUCTの顕著な人権課題</h2>
                                            <ul style={{ listStyleType: "disc", marginLeft: "40px" }}>
                                                <li>データ保護とプライバシー</li>
                                                <li>AIなどを使ったサービスと人権</li>
                                                <li>人種、信条、国籍、民族、宗教などによるあらゆる差別</li>
                                                <li>各種ハラスメント</li>
                                                <li>従業員の労働環境および安全と健康の維持</li>
                                            </ul>
                                        </div>
                                        <div className="t-privacy-wrap">
                                            <h2>推進体制</h2>
                                            <p>
                                                &nbsp;&nbsp;DATAFLUCTグループ全体の人権尊重の取り組みは、当社のCEOが統括しています。またUNGPに則った人権方針の取り組み進捗は、経営管理本部を事務局とし、経営管理本部の担当役員が取締役会で定期的に報告し、取締役会がそれを監督していきます。
                                            </p>

                                        </div>
                                        <div className="t-privacy-wrap">
                                            <h2>苦情処理メカニズム</h2>
                                            <p>
                                                &nbsp;&nbsp;DATAFLUCTは、人権侵害の恐れが発生したときに、迅速に問題の是正に取り組みます。また、匿名で通報可能な通報窓口を設置し、通報者や通報内容の秘密を適切に取り扱います。通報者に対する不利益な取り扱いや報復を禁止し、通報者の保護を徹底します。
                                            </p>
                                        </div>
                                        <div className="t-privacy-wrap">
                                            <h2>情報開示</h2>
                                            <p>
                                                &nbsp;&nbsp;今後もステークホルダーとの継続的な対話を行うとともに、人権の尊重に関するより一層の取り組みを進め、適時・適切に情報を開示していきます。
                                            </p>
                                        </div>
                                        <div className="t-privacy-wrap">
                                            <h2>役員・従業員向け教育・啓発</h2>
                                            <p>
                                                &nbsp;&nbsp;DATAFLUCTは、人権尊重の担い手となるすべての役員・従業員に対して、人権尊重の意識の深化、グローバルな人権課題の動向への理解促進を図るため、研修をはじめとした啓発活動を実施しています。また、推進をするにあたり当社では、グループ全従業員を対象にDATAFLUCTグループの人権方針の説明や人権関連の配慮事項に関するガイドラインを含んだ、「企業と人権」、「データ保護とプライバシー」などをテーマとした研修を実施する予定です。
                                            </p>
                                        </div>

                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export function Head() {
    return (
        <Seo
            title="人権ポリシー"
            description="DATAFLUCT"
            pathname=""
        />
    )
}
